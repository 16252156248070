<template>
  <div class="conent">
    <van-image
      class="image"
      width="100%"
      height="auto"
      src="https://qn-dev.ifinnet.cn/yearReportTop01.png"
    />
    <div class="name">
      <van-image
        class="images"
        width="100%"
        height="100%"
        :src="require('../assets/images/nav.png')"
      />
      <div class="information">
        <h1>{{ baseInfo.customerName }}</h1>
        <p>{{ baseInfo.beginDate }}-{{ baseInfo.endDate }}</p>
      </div>
    </div>
    <div class="earnings">
      <p style="display: flex; align-items: center">
        本年账户收益
        <van-image
          class="image"
          style="margin-left: 8px"
          width="16px"
          height="16px"
          @click="() => (show = true)"
          src="https://qn-dev.ifinnet.cn/%E6%8F%90%E7%A4%BA.svg"
        />
      </p>
      <div>
        <h1>{{ baseInfo.monthReturnSum }}</h1>
        <span>{{ baseInfo.currency }}</span>
      </div>
    </div>
    <!-- 账户总值 -->
    <Movements
      :currency="baseInfo.currency"
      :begin="baseInfo.beginAccountSum"
      :trendInfo="trendInfo"
      :end="baseInfo.endAccountSum"
      :userUuid="userUuid"
      :year="year"
    />
    <!-- 收益率 -->
    <Earnings :userUuid="userUuid" :year="year" />
    <!-- 供款情况 -->
    <Contribution :userUuid="userUuid" :year="year" />
    <div class="tzImg">
      <van-image
        v-if="picUrl != ''"
        class="image"
        width="100%"
        height="auto"
        @click="goImg"
        :src="picUrl"
      />
    </div>
    <div class="foot">
      <div class="main">
        账户的具体基金收益、费用扣款等内容，请前往“方雷环球”公众号查看。
      </div>
      <van-image
        class="image"
        width="1.5rem"
        height="1.5rem"
        fit="cover"
        src="https://qn-dev.ifinnet.cn/monthreport/qrcode_1280.jpeg"
        @click="getImg"
      />
    </div>
    <Popup v-model="show">
      <div class="popup">
        <div class="title">账户收益公式</div>
        <div class="p">
          本年账户收益 = 年末账户总值 - 年初账户总值 - 本年累计供款
        </div>
        <div class="btn" @click="() => (show = false)">知道了</div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { Image as VanImage, ImagePreview } from "vant";
import { Popup } from "vant";
import Movements from "../components/Movements1.vue";
import Earnings from "../components/Earnings1.vue";
import Contribution from "../components/Contribution1.vue";
export default {
  components: {
    Movements,
    Earnings,
    Contribution,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    Popup,
  },
  data() {
    return {
      ewm: require("../assets/images/ewm.png"),
      baseInfo: {},
      trendInfo: {},
      articleUrl: "",
      picUrl: "",
      userUuid: "",
      year: "",
      show: false,
    };
  },

  mounted() {
    // let pp = localStorage.getItem('code')
    // console.log(pp);
    // this.$util.get('itaMonthReport/wxLogin',{
    //     jsCode:'031HEhll2eu2J748Rgml2GfRmF1HEhlq'
    // }).then(res=>{
    //     console.log(res);
    // })

    if (this.$route.query.userUuid) {
      this.userUuid = this.$route.query.userUuid;
      this.year = this.$route.query.year;
      // this.userUuid = "ff40c7d2ae8f40a9bb01c176e208fb4c";

      this.allData();
    } else {
      this.userUuid = localStorage.getItem("userUuid");
      // this.userUuid = "ff40c7d2ae8f40a9bb01c176e208fb4c";
      this.allData();
    }
    this.getFuilter();
  },

  methods: {
    allData() {
      this.$util
        .get("itaMonthReport/yearReport/getAccountReturnInfo", {
          userUuid: this.userUuid,
          year: this.year,
        })
        .then((res) => {
          res.baseInfo.beginDate = res.baseInfo.beginDate.replace(/-/g, "/");
          res.baseInfo.endDate = res.baseInfo.endDate.replace(/-/g, "/");
          const list = [];
          res.trendInfo.dateList.forEach((el) => {
            list.push(el.replace(/-/g, "/"));
          });
          res.trendInfo.dateList = [...new Set(list)];
          this.baseInfo = res.baseInfo;
          this.trendInfo = res.trendInfo;
        });
    },
    getFuilter() {
      this.$util
        .get("itaMonthReport/getMonthReportArticle", {
          userUuid: this.userUuid,
          year: this.year,
        })
        .then((res) => {
          this.articleUrl = res.articleUrl;
          this.picUrl = res.picUrl;
        });
    },
    // 预览
    getImg() {
      ImagePreview({
        images: ["https://qn-dev.ifinnet.cn/monthreport/qrcode_1280.jpeg"],
        showIndex: true,
        loop: true,
        startPosition: 0,
      });
    },
    // 外链
    goImg() {
      const url = this.articleUrl;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.image {
  display: block;
}
.name {
  // background: url("../assets/images/nav.png") round;
  position: relative;
  .images {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .information {
    position: relative;
    z-index: 100;
    padding: 32px;
    // border-bottom: 1px solid #f5f5f5;
    h1 {
      font-size: 41px;
    }
    p {
      margin-top: 16px;
      font-size: 30px;
      color: #828282;
    }
  }
}
.earnings {
  padding: 16px 32px 32px;
  border-bottom: 1px solid #f5f5f5;
  p {
    font-size: 24px;
    color: #828282;
    font-weight: bold;
  }
  div {
    display: flex;
    align-items: flex-end;
    h1 {
      font-size: 80px;
      color: #0837f6;
    }
    span {
      font-size: 24px;
      color: #bdbdbd;
      display: block;
      margin: 0 0 16px 16px;
      font-weight: bold;
    }
  }
}
.tzImg {
  margin: 0 32px;
  padding-bottom: 48px;
  border-bottom: 1px solid #f5f5f5;
}
.foot {
  padding: 48px 32px;
  display: flex;
  justify-content: space-between;
  .main {
    max-width: 432px;
    font-size: 24px;
    color: #828282;
    line-height: 48px;
  }
}
.van-popup {
  background-color: rgba(0, 0, 0, 0) !important;
}
.popup {
  width: 600px;
  padding: 32px 32px 0 32px;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 64px;
    text-align: center;
    color: #333333;
    padding-bottom: 32px;
  }
  .p {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #828282;
  }
  .btn {
    margin-top: 64px;
    height: 112px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 32px;
    line-height: 112px;
    text-align: center;
    color: #333333;
  }
}
</style>
