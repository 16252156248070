<template>
  <div class="content">
    <p class="month">本年账户走势</p>
    <div id="myChart"></div>
    <div class="account">
      <div class="first-month">
        <p>年初账户总值</p>
        <div>
          <h1>{{ begin }}</h1>
          <span>{{ currency }}</span>
        </div>
      </div>
      <div class="first-month">
        <p>年末账户总值</p>
        <div>
          <h1>{{ end }}</h1>
          <span>{{ currency }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    end: String,
    begin: String,
    trendInfo: Object,
    currency: String,
    userUuid: String,
    year: String,
  },
  data() {
    return {
      maxValue: "",
      minValue: "",
    };
  },
  watch: {
    trendInfo(newval) {
      this.maxValue = newval.dataList[0];
      newval.dataList.forEach(
        (item) => (this.maxValue = item > this.maxValue ? item : this.maxValue)
      );
      this.minValue = newval.dataList[0];
      newval.dataList.forEach(
        (item) => (this.minValue = item < this.minValue ? item : this.minValue)
      );
      this.trendInfo = newval;
      this.drawLine();
    },
    immediate: true,
  },
  mounted() {
    // this.drawLine();
  },
  methods: {
    drawLine() {
      const sum = this.trendInfo.dateList.length - 2;
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        // grid: {
        //     x: 30,
        //     x2: 30,
        // },
        xAxis: {
          type: "category",
          data: this.trendInfo.dateList,
          splitLine: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            // show:false
            lineStyle: {
              color: "#F2F2F2",
            },
          },
          axisLabel: {
            interval: sum,
            textStyle: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              color: "#BDBDBD",
              fontSize: 12,
            },
            fontWeight: "bold",
            margin: 12,
          },
        },
        yAxis: {
          show: false,
          max: this.maxValue,
          min: this.minValue,
        },
        series: [
          {
            data: this.trendInfo.dataList,
            type: "line",
            smooth: true,
            symbol: "none",
            color: "#0837F6",
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 50px 32px 0;
}
.month {
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 32px;
}
#myChart {
  width: 100%;
  height: 352px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}
.account {
  display: flex;
  padding: 48px 0;
  .first-month {
    flex: 1;
    p {
      font-size: 24px;
      color: #828282;
      font-weight: bold;
    }
    div {
      display: flex;
      align-items: flex-end;
      h1 {
        font-size: 46px;
        color: #000;
        margin: 18px 16px 0 0;
      }
      span {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: bold;
        color: #bdbdbd;
      }
    }
  }
}
</style>
